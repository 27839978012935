import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Spinner } from '@condenast/gemini';
import { ChevronDownIcon, CheckIcon } from '@condenast/gemini/icons';
import { useSession } from '../Context/SessionContext';
import { useCreateSessionObject, useGetField } from '../Hooks/qlik-hooks/Doc';
import { useGetLayout } from '../Hooks/qlik-hooks/GenericObject';
import PropTypes from 'prop-types';
import { useSelect, useSelectAll } from '../Hooks/qlik-hooks/Field';
import { useComponentVisible } from '../Hooks/useComponentVisible';
import { ALL_TRAFFIC, GLOBAL_ALL_TRAFFIC, ALL_SITE_LIST } from '../Utils/Constants';
import '../index.css';
import componentList from '../Components/logos.js';

const SiteDropDown = ({
  expression,
  label,
  filters,
  setFilters,
  defaultsite = false,
}) => {
  const { app, brandName } = useSession();
  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  const [data, setData] = useState([]);
  const location = useLocation();
  const currentComponent = componentList.find((site) => location.pathname.includes(site.href)) || {};
  const brandValue = currentComponent.name || null;

  const [selected, setSelected] = useState((filters && filters.site) || null);
  const [inputValue, setInputValue] = useState('');
  const [isData, setIsData] = useState(false);
  const [brandView,setBrandView] = useState(location?.pathname === '/app')

  useEffect(()=>{
    setBrandView(location?.pathname === '/app')
  },[location.pathname])

  const field = useGetField(app, { params: [expression] });

  const select = useSelect(field);
  const selectAll = useSelectAll(field);
  const { site } = filters;
  const isGlobalApp = brandName === 'global';
  const navigate = useNavigate();

  const listObject = useCreateSessionObject(app, {
    params: [
      {
        qInfo: { qType: 'listbox' },
        qListObjectDef: {
          qDef: { qFieldDefs: [expression] },
          qInitialDataFetch: [{ qWidth: 1, qHeight: 1000 }],
        },
        qSelectionObjectDef: {},
      },
    ],
  });
  const listObjectLayout = useGetLayout(listObject, {
    params: [],
    invalidations: true,
  });

  useEffect(() => {
    if (listObjectLayout?.qResponse?.qListObject?.qDataPages?.[0]?.qMatrix) {
      let siteOptions =
        listObjectLayout?.qResponse?.qListObject?.qDataPages?.[0]?.qMatrix?.map(
          (item) => ({
            qText: item[0].qText,
            qElement: item[0].qElemNumber,
            qState: item[0].qState,
          })
        ) || [];

      setData(siteOptions);
      setIsData(true);
    }
  }, [listObjectLayout, defaultsite]);

  useEffect(() => {
    if (selected) {
      // In case of global app, this gets redirected to single brand view hence thsi is not required
      if (!isGlobalApp) {
        if (selected === ALL_TRAFFIC) {
          selectAll.call();
        } else {
          select.call(selected);
        }
      }
      setFilters({
        ...filters,
        site: selected,
      });
    }
  }, [selected]);

  const handleItemSelect = (item) => {
    //select.call(item.qText);
    if (brandView) {
      Object.keys(ALL_SITE_LIST).forEach((brnd) => {
        if (brnd.toLowerCase() === item.qText.toLowerCase()) {
          setFilters({
            ...filters,
            site: item.qText, 
          });
          //navigate(`/${ALL_SITE_LIST[brnd]}`);
          window.location.href = `/${ALL_SITE_LIST[brnd]}`
        }
      });
    } else {
      setSelected(item.qText);
    }
    setIsComponentVisible(false);
    
  };

  const handleAllSelect = () => {
    //selectAll.call();
    setSelected(ALL_TRAFFIC);
    setFilters({
      ...filters,
      site: ALL_TRAFFIC,
    });
    setIsComponentVisible(false);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const filteredData = inputValue
    ? inputValue === selected
      ? data
      : data.filter((item) =>
          item.qText.toLowerCase().includes(inputValue.toLowerCase() || '')
        )
    : data;

  useEffect(() => {
    if (filteredData.length === 1) {
      setSelected(filteredData[0].qText);
    }
  }, [filteredData, defaultsite]);

  return (
    <div className="relative " ref={ref}>
      <button
        className={`border bg-gray-6  dark:text-white dark:bg-zinc-800 cursor-pointer border-gray-5 font-ibm-sans  text-gray-1  text-[16px] normal font-[400] leading-[24px] rounded-[4px]   px-2.5 py-2.5 inline-flex items-center w-72  justify-between dark:bg-gray-800 dark:border-gray-3`}
        type="button"
        onClick={() => {
          setIsComponentVisible(!isComponentVisible);
          setInputValue('');
        }}
      >
        {site || label}

          <span className="px-2 pt-[4px]">
            <ChevronDownIcon size="small" />
          </span>

      </button>

      {(filteredData.length >= 1 && isComponentVisible && (
        <div className="absolute z-10 h-[250px] overflow-auto bg-white divide-y divide-gray-100 rounded-sm  mt-1 shadow w-72 dark:bg-gray-700">
          {data.length === 0 ? ( // Loader when data is loading
            <div className="flex justify-center items-center h-[250px]">
              <Spinner />
            </div>
          ) : (
            <ul
              className="py-2 text-sm text-gray-700 dark:text-gray-200 "
              aria-labelledby="dropdownDefaultButton"
            >
              <li className="p-2">
                <input
                  className="border bg-white  font-ibm-sans  text-gray-1  text-[16px] normal font-[400] leading-[24px] px-2.5 py-2.5 inline-flex items-center w-[100%]  justify-between dark:text-white dark:bg-gray-700"
                  type="text"
                  placeholder={'search'}
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </li>
              {!brandView && (
                  <li>
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white"
                      onClick={() => window.location.href = '/app'}
                    >
                      {GLOBAL_ALL_TRAFFIC}
                    </div>
                  </li>
              )}
              {filteredData.length > 1 && (
                  <li>
                    <div
                      className="flex justify-between px-4 py-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white"
                      onClick={handleAllSelect}
                    >
                      {brandValue} {ALL_TRAFFIC}
                      {filters.site === ALL_TRAFFIC && <CheckIcon size="small" />}
                    </div>
                  </li>
              )}
              {filteredData.length
                ? filteredData.map((list, l) => {
                    return (
                      <li key={l}>
                        <div
                          className={`flex justify-between px-4 py-2  font-ibm-sans  text-gray-1  text-[16px] normal font-[400] leading-[24px] ${
                            filteredData.length > 1 ? `cursor-pointer` : null
                          }  hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-white`}
                          onClick={() => {
                            handleItemSelect(list);
                          }}
                        >
                          {list.qText}
                          <span className="px-2">
                            {list.qState === 'S' &&
                            filters.site !== ALL_TRAFFIC ? (
                              <CheckIcon size="small" />
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </li>
                    );
                  })
                : isData && (
                    <div className="flex justify-center items-center font-ibm-sans  text-gray-1  text-[16px] normal font-[400] leading-[24px] h-[250px]">
                      No matching data
                    </div>
                  )}
            </ul>
          )}
        </div>
      )) || <></>}
    </div>
  );
};

SiteDropDown.propTypes = {
  expression: PropTypes.string,
  label: PropTypes.string,
};

export default SiteDropDown;
